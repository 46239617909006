import React from "react";
import { Box, makeStyles, Paper, useMediaQuery } from "@material-ui/core";
import { graphql, Link } from "gatsby";
import Layout from "../Layout/Layout";

export const query = graphql`
  query GetProductQuery($productId: String) {
    allWpCategory(filter: { id: { eq: $productId } }) {
      nodes {
        name
        id
        description
        danh_muc {
          image {
            sourceUrl
          }
        }
        wpParent {
          node {
            name
            slug
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles(() => ({
  img: {
    width: "100%",
    height: "auto",
    objectFit: "cover",
  },
}));

const ProductDetails = ({ data }) => {
  const classes = useStyles();
  const breakpoints = useMediaQuery("(max-width: 765px)");
  const breakpoints450 = useMediaQuery("(max-width: 450px)");

  const product = data.allWpCategory.nodes[0];

  return (
    <Layout>
      <Box
        width={breakpoints ? "95%" : "80%"}
        mx="auto"
        mt={5}
        component={Paper}
        p={breakpoints ? 2 : 5}
      >
        <Box
          fontSize={18}
          fontWeight={700}
          display="flex"
          flexWrap="wrap"
          color="#551a8b"
          mb={3}
        >
          <Link to="/" style={{ color: "#308632" }}>
            Trang chủ
          </Link>
          &nbsp;<Box style={{ color: "#308632" }}>/</Box>&nbsp;
          <Link
            to={`/danh-muc-san-pham/${product?.wpParent?.node?.slug}`}
            style={{ color: "#308632" }}
          >
            {product?.wpParent?.node?.name}
          </Link>
          &nbsp;<Box style={{ color: "#308632" }}>/</Box>&nbsp;
          <Box color="black">{product?.name}</Box>
        </Box>
        <Box display={breakpoints450 ? undefined : "flex"}>
          <Box width={breakpoints450 ? "100%" : "40%"}>
            <img
              className={classes.img}
              src={product?.danh_muc?.image?.sourceUrl}
              alt=""
            />
          </Box>
          <Box ml={breakpoints450 ? 0 : 4}>
            <Box fontSize={30} fontWeight={700}>
              {product?.name}
            </Box>
            <Box>{product?.description}</Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default ProductDetails;
